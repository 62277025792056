import request from '@/utils/request';

// 获取表格数据
export const getTableData = params =>
  request.post('/exchange-api/founds/queryEntryFundList', params);

// 删除
export const deleteData = params =>
  request.post('/exchange-api/founds/deleteFundsEntry', params);

// 发起结汇
export const sendExchange = params =>
  request.post('/exchange-api/founds/applySettlementOfExchange', params);

// 文件下载
export const downloadFile = params =>
  request.post('/exchange-api/founds/doDownloadFile', params, {
    responseType: 'blob',
    headers: { 'Content-Type': 'multipart/form-data' },
  });

// 批量下发获取表格数据
export const getDetailTableData = params =>
  request.post('/exchange-api/founds/queryEntryFundPayList', params);

// 批量下发删除
export const detailDeleteData = params =>
  request.post('/exchange-api/founds/deleteFundsEntryPay', params);

// 获取批量明细
export const getDetailDataList = params =>
  request.post('/exchange-api/founds/queryEntryFundPayDetailList', params);

// 执行操作
export const applyPay = params =>
  request.post('/exchange-api/founds/applyPay', params);

export const getCompanyId = () => request.get('/exchange-api/limit/get');
