<template>
  <div class="container">
    <div style="display: flex; align-items: center; margin-bottom: 30px">
      <el-button
        type="primary"
        size="small"
        style="margin-right: 20px"
        @click="$router.go(-1)"
      >
        &lt; 返回上级
      </el-button>
      <h2>批量下发</h2>
    </div>
    <div style="display: flex; align-items: center">
      <p style="margin-right: 30px; font-weight: bold; font-size: 16px">
        待下发金额：{{ noIssueAmount }} 元
      </p>
      <p>提示：下发金额不得大于当前待下发余额</p>
    </div>
    <div style="text-align: right; margin-bottom: 20px">
      <el-button
        type="primary"
        size="small"
        @click="
          uploadDialog = true;
          fileList = [];
        "
      >
        导入批次
      </el-button>
    </div>
    <div v-loading="loading">
      <el-table :data="tableData" stripe border style="width: 100%">
        <el-table-column prop="index" label="序号" width="50">
          <template slot-scope="scope">
            <span>
              {{ (currentPage - 1) * pageSize + scope.$index + 1 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="fileBatchNo"
          label="代付批次号"
          width="180"
        ></el-table-column>
        <el-table-column prop="fileName" label="文件名" width="180px">
          <template slot-scope="scope">
            <a
              href="javascript:void (0);"
              @click="handleDownloadFile(scope.row)"
            >
              {{ scope.row.fileName }}
            </a>
          </template>
        </el-table-column>
        <el-table-column label="代付金额" prop="amount"></el-table-column>
        <el-table-column label="成功金额" prop="issueAmount"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <span>
              {{ getStatusName(scope.row.status) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              v-show="[1, 2, 3].includes(scope.row.status)"
              style="margin-right: 10px"
              size="mini"
              type="primary"
              :loading="scope.row.loading"
              @click="handleClickDetail(scope.row)"
            >
              批次明细
            </el-button>
            <el-popconfirm
              title="确定删除当前内容？"
              @confirm="onDelete(scope.row)"
            >
              <el-button
                v-show="scope.row.status === 1"
                slot="reference"
                size="mini"
                type="danger"
              >
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      title="导入批次"
      :visible.sync="uploadDialog"
      :close-on-click-modal="false"
      :before-close="exchangeBeforeClose"
      width="500px"
    >
      <div style="display: flex; flex-direction: column">
        <div style="display: flex; align-items: center; margin-bottom: 20px">
          <el-upload
            ref="upload"
            :headers="uploadHeader"
            :action="`${config.baseApi}/exchange-api/founds/uploadFundsEntryPayFile`"
            :on-success="handleFileSuccess"
            :on-error="handleFileError"
            :data="{
              fileName: fileList.length > 0 ? fileList[0].name : '',
              id: $route.query.id,
            }"
            :auto-upload="false"
            :show-file-list="false"
            :file-list="fileList"
            :on-change="handleUpdateChange"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <a :href="downloadUrl" style="margin-left: 20px">点击下载模版</a>
        </div>
        <div style="padding-left: 10px">
          {{ fileList.length > 0 ? fileList[0].name : '' }}
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="uploadDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload" :loading="btnLoading">
          确 定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="下发详情"
      :visible.sync="detailDialog"
      :close-on-click-modal="false"
      :before-close="exchangeBeforeClose"
      width="1000px"
    >
      <div>
        <div v-loading="detailLoading">
          <el-table :data="detailTableData" stripe border style="width: 100%">
            <el-table-column prop="index" label="序号" width="50">
              <template slot-scope="scope">
                <span>
                  {{
                    (detailCurrentPage - 1) * detailPageSize + scope.$index + 1
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="fileBatchNo"
              label="批次号"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="inName"
              label="收款方名称"
              width="180"
            ></el-table-column>
            <el-table-column label="卡号" prop="inNo"></el-table-column>
            <el-table-column label="下发金额" prop="amount"></el-table-column>
            <el-table-column
              label="提交日期"
              prop="createTime"
            ></el-table-column>
            <el-table-column
              label="完成日期"
              prop="completionTime"
            ></el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <span>
                  {{ getStatusName2(scope.row.status) }}
                </span>
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination-container">
            <el-pagination
              @size-change="handleDetailSizeChange"
              @current-change="handleDetailCurrentChange"
              :page-sizes="[5, 10, 20]"
              layout="total, sizes, prev, pager, next"
              :current-page="detailCurrentPage"
              :page-size="detailPageSize"
              :total="detailTotal"
            ></el-pagination>
          </div>
          <div style="display: flex; gap: 30px">
            <p>
              总金额：
              {{ detailTableData.length && detailTableData[0].totalAmount }}
            </p>
            <p>
              总笔数：{{
                detailTableData.length && detailTableData[0].totalNum
              }}
            </p>
            <p>
              成功笔数：{{
                detailTableData.length && detailTableData[0].successNum
              }}
            </p>
            <p>
              成功金额：{{
                detailTableData.length && detailTableData[0].successAmount
              }}
            </p>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="detailDialog = false">取 消</el-button>
        <el-button
          v-show="detailTableData[0]?.status === 1"
          type="primary"
          @click="handleImplement"
          :loading="implementLoading"
        >
          执行
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  applyPay,
  detailDeleteData,
  downloadFile,
  getDetailDataList,
  getDetailTableData,
  getTableData,
} from 'views/main/funds-inflow/api';
import storage from '@/utils/storage';
import config from '@/config';

export default {
  data() {
    return {
      loading: true,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      statusList: [
        {
          value: 1,
          name: '待执行',
        },
        {
          value: 2,
          name: '执行中',
        },
        {
          value: 3,
          name: '执行完成',
        },
        {
          value: 4,
          name: '执行失败',
        },
      ],
      statusList2: [
        {
          value: 1,
          text: '待执行',
        },
        {
          value: 2,
          text: '处理中',
        },
        {
          value: 3,
          text: '出款成功',
        },
        {
          value: 4,
          text: '出款失败',
        },
      ],
      uploadDialog: false,
      fileList: [],
      uploadHeader: { Authorization: storage.getItem('token') },
      config,
      downloadUrl: '',
      btnLoading: false,
      detailDialog: false,
      detailTableData: [],
      detailLoading: false,
      detailCurrentPage: 1,
      detailTotal: 0,
      detailPageSize: 10,
      implementLoading: false,
      detail: '',
      noIssueAmount: '',
    };
  },
  mounted() {
    const env = process.env.NODE_ENV;

    if (env === 'development') {
      this.downloadUrl = '/templateEntryPay.xlsx';
    } else {
      this.downloadUrl = '/exchange/templateEntryPay.xlsx';
    }
    this.getTableData();
    this.getNoIssueAmount();
  },
  methods: {
    async getNoIssueAmount() {
      const res = await getTableData({
        pageNum: 1,
        pageSize: 10,
        serialNo: this.$route.query.serialNo,
      });
      this.noIssueAmount = res.dataSet[0].noIssueAmount;
    },
    async handleClickDetail(row) {
      this.detail = row;
      row.loading = true;
      try {
        await this._getDetailDataList();
        row.loading = false;
      } catch (e) {
        console.log(e);
        row.loading = false;
      }
    },
    async _getDetailDataList() {
      const res = await getDetailDataList({
        pageNum: this.detailCurrentPage,
        pageSize: this.detailPageSize,
        serialNo: this.detail.serialNo,
        fileBatchNo: this.detail.fileBatchNo,
      });
      this.detailTableData = res.dataSet;
      this.detailDialog = true;
      this.detailTotal = res.pages.total;
    },
    async handleImplement() {
      this.implementLoading = true;
      try {
        await applyPay({ id: this.detail.id });
        this.$message.success('执行成功');
        this.getTableData();
        this.getNoIssueAmount();
        this.detailDialog = false;
        this.implementLoading = false;
      } catch (e) {
        console.log(e);
        this.implementLoading = false;
      }
    },
    async onDelete(row) {
      try {
        await detailDeleteData({ id: row.id });
        this.getTableData();
        this.$message.success('删除成功');
      } catch (e) {
        this.$message.error('删除失败');
      }
    },
    async handleDownloadFile({ fileUrl, fileName }) {
      try {
        const params = new FormData();
        params.append('fileUrl', fileUrl);
        params.append('fileName', fileName);
        await downloadFile(params);
        this.$message.success('下载成功');
      } catch (e) {
        console.log(e);
      }
    },
    async getTableData() {
      this.loading = true;
      try {
        const res = await getDetailTableData({
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          serialNo: this.$route.query.serialNo,
        });
        this.tableData = res?.dataSet.map(item => ({
          ...item,
          loading: false,
        }));
        this.total = res?.pages?.total;
      } catch (e) {
        console.log(e, 'error');
      }
      this.loading = false;
    },
    getStatusName(status) {
      return this.statusList.find(item => item.value === status)?.name;
    },
    getStatusName2(status) {
      return this.statusList2.find(item => item.value === status)?.text;
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    handleDetailSizeChange(val) {
      this.detailPageSize = val;
      this.detailCurrentPage = 1;
      this._getDetailDataList();
    },
    handleDetailCurrentChange(val) {
      this.detailCurrentPage = val;
      this._getDetailDataList();
    },
    exchangeBeforeClose(done) {
      this.fileList = [];
      done();
    },
    handleFileSuccess(res) {
      if (res.code === 100200) {
        this.getTableData();
        this.uploadDialog = false;
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
      this.btnLoading = false;
      this.$refs.upload.uploadFiles.forEach(file => (file.status = 'ready'));
    },
    handleFileError() {
      this.btnLoading = false;
      this.$refs.upload.uploadFiles.forEach(file => (file.status = 'ready'));
    },
    handleUpdateChange(file) {
      this.fileList.splice(0, 1, file);
    },
    submitUpload() {
      if (this.fileList.length <= 0) {
        this.$message.warning('请上传文件');
        return;
      }
      this.btnLoading = true;
      this.$refs.upload.submit();
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  margin: 0 auto;
  padding: 40px 5%;
}
h2 {
  margin-bottom: 0;
}

.pagination-container {
  margin-top: 20px;
  text-align: right;
}
</style>
